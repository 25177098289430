// ORDER_CANCELLATION:   "ORDER_CANCELLATION",
// ORDER_INQUIRY:        "ORDER_INQUIRY",
// PROMOTION_AND_REWARD: "PROMOTION_AND_REWARD",
// BUYMED_ACCOUNT:       "BUYMED_ACCOUNT",
// CLAIM_PRODUCT:        "CLAIM_PRODUCT",
// DELIVERY_INQUIRY:     "DELIVERY_INQUIRY",
// PAYMENT_INQUIRY:      "PAYMENT_INQUIRY",
// COMPLAINT:            "COMPLAINT",
// TECHNICAL_ISSUE:      "TECHNICAL_ISSUE",
// PRICE_RECOMMENDATION: "PRICE_RECOMMENDATION",

const MapTicketTypeName = { PRODUCT: 'Sản phẩm', ORDER: 'Đơn hàng', OTHER: 'Khác', ACCOUNT: 'Tài khoản', PROMOTION: 'Khuyến mãi' };

const TicketEnums = {
  PRODUCT: 'PRODUCT',
  ACCOUNT: 'ACCOUNT',
  ORDER: 'ORDER',
  OTHER: 'OTHER',
  PROMOTION: 'PROMOTION',
  ORDER_CANCELLATION: 'ORDER_CANCELLATION',
  ORDER_INQUIRY: 'ORDER_INQUIRY',
  PROMOTION_AND_REWARD: 'PROMOTION_AND_REWARD',
  BUYMED_ACCOUNT: 'BUYMED_ACCOUNT',
  CLAIM_PRODUCT: 'CLAIM_PRODUCT',
  DELIVERY_INQUIRY: 'DELIVERY_INQUIRY',
  PAYMENT_INQUIRY: 'PAYMENT_INQUIRY',
  COMPLAINT: 'COMPLAINT',
  TECHNICAL_ISSUE: 'TECHNICAL_ISSUE',
  PRICE_RECOMMENDATION: 'PRICE_RECOMMENDATION',
};

const TicketLabelEnums = {
  PRODUCT: {
    code: 'PRODUCT',
    label: 'Sản phẩm',
  },
  ACCOUNT: {
    code: 'ACCOUNT',
    label: 'Tài khoản',
  },
  ORDER: {
    code: 'ORDER',
    label: 'Đơn hàng',
  },
  OTHER: {
    code: 'OTHER',
    label: 'Ý kiến đóng góp',
  },
  PROMOTION: {
    code: 'PROMOTION',
    label: 'Khuyến mãi',
  },
  ORDER_CANCELLATION: {
    code: 'ORDER_CANCELLATION',
    label: 'Hủy đơn hàng',
  },
  ORDER_INQUIRY: { code: 'ORDER_INQUIRY', label: 'Yêu cầu đặt hàng' },
  PROMOTION_AND_REWARD: { code: 'PROMOTION_AND_REWARD' },
  BUYMED_ACCOUNT: { code: 'BUYMED_ACCOUNT' },
  CLAIM_PRODUCT: { code: 'CLAIM_PRODUCT' },
  DELIVERY_INQUIRY: { code: 'DELIVERY_INQUIRY' },
  PAYMENT_INQUIRY: { code: 'PAYMENT_INQUIRY' },
  COMPLAINT: { code: 'COMPLAINT' },
  TECHNICAL_ISSUE: { code: 'TECHNICAL_ISSUE' },
  PRICE_RECOMMENDATION: { code: 'PRICE_RECOMMENDATION' },
};

const TicketStatus = {
  PENDING: {
    code: 'PENDING',
    label: 'Chưa xử lý',
  },
  IN_PROCESS: {
    code: 'IN_PROCESS',
    label: 'Đang xử lý',
  },
  REPLIED: {
    code: 'REPLIED',
    label: 'Đã trả lời',
  },
  CANCELLED: {
    code: 'CANCELLED',
    label: 'Đã hủy',
  },
  DONE: {
    code: 'DONE',
    label: 'Hoàn tất',
  },
};
const TYPE_OF_STATUS = {
  PENDING: 'Chưa Xử Lý',
  IN_PROCESS: 'Đang Xử Lý',
  DONE: 'Đã Xử Lý',
  REPLIED: 'Đã Trả Lời',
  CANCELLED: 'Đã Hủy',
};

const BACKGROUND_COLOR_STATUS = {
  PENDING: 'rgb(204 85 85)',
  IN_PROCESS: 'rgb(248 142 31)',
  DONE: '#15A959',
  REPLIED: '#788FCA',
  CANCELLED: 'rgb(187 187 187)',
};
const TYPE_OF_TICKET_SUPPORT = {
  PROMOTION: 'Khuyến mãi',
  ACCOUNT: 'Tài Khoản',
  PRODUCT: 'Sản Phẩm',
  ORDER: 'Đơn Hàng',
  OTHER: 'Khác',
};

// ORDER_CANCELLATION:   "ORDER_CANCELLATION",
// ORDER_INQUIRY:        "ORDER_INQUIRY",
// PROMOTION_AND_REWARD: "PROMOTION_AND_REWARD",
// BUYMED_ACCOUNT:       "BUYMED_ACCOUNT",
// CLAIM_PRODUCT:        "CLAIM_PRODUCT",
// DELIVERY_INQUIRY:     "DELIVERY_INQUIRY",
// PAYMENT_INQUIRY:      "PAYMENT_INQUIRY",
// COMPLAINT:            "COMPLAINT",
// TECHNICAL_ISSUE:      "TECHNICAL_ISSUE",
// PRICE_RECOMMENDATION: "PRICE_RECOMMENDATION",

const TicketTypes = [
  // 'PRODUCT',
  // 'ACCOUNT',
  // 'ORDER',
  // 'OTHER',
  // 'PROMOTION',
  'ORDER_CANCELLATION',
  'ORDER_INQUIRY',
  'PROMOTION_AND_REWARD',
  'BUYMED_ACCOUNT',
  'CLAIM_PRODUCT',
  'DELIVERY_INQUIRY',
  'PAYMENT_INQUIRY',
  'COMPLAINT',
  'TECHNICAL_ISSUE',
  'PRICE_RECOMMENDATION',
];
const TicketTypesNotActive = ['ACCOUNT', 'PRODUCT', 'OTHER'];
const TicketTypesPageFeedback = ['ACCOUNT'];

export default {
  MapTicketTypeName,
  TicketEnums,
  TicketLabelEnums,
  TicketTypes,
  TicketStatus,
  TicketTypesNotActive,
  TicketTypesPageFeedback,
  TYPE_OF_STATUS,
  BACKGROUND_COLOR_STATUS,
  TYPE_OF_TICKET_SUPPORT,
};
