import { KbankGatewayClient } from "clients";

export const getQRChargeInfo = async ({ chargeId = '' }) => {
    const result = await KbankGatewayClient.getQRChargeInfo({ chargeId });

    return result;
};

export default {
    getQRChargeInfo,
};